import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "aside-primary d-flex flex-column align-items-lg-center flex-row-auto" }
const _hoisted_2 = {
  class: "aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10",
  id: "kt_aside_logo"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0",
  id: "kt_aside_nav"
}
const _hoisted_6 = {
  class: "hover-scroll-y mb-10",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-wrappers": "#kt_aside_nav",
  "data-kt-scroll-dependencies": "#kt_aside_logo, #kt_aside_footer",
  "data-kt-scroll-offset": "0px"
}
const _hoisted_7 = { class: "nav flex-column" }
const _hoisted_8 = {
  class: "nav-item mb-2 d-none",
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  "data-bs-placement": "right",
  "data-bs-dismiss": "click",
  title: "",
  "data-bs-original-title": "Projects"
}
const _hoisted_9 = {
  class: "nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
  "data-bs-toggle": "tab",
  href: "#kt_aside_nav_tab_projects"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2x" }
const _hoisted_11 = {
  class: "nav-item mb-2",
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  "data-bs-placement": "right",
  "data-bs-dismiss": "click",
  title: "",
  "data-bs-original-title": "Menu"
}
const _hoisted_12 = {
  class: "nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light active",
  "data-bs-toggle": "tab",
  href: "#kt_aside_nav_tab_menu"
}
const _hoisted_13 = { class: "svg-icon svg-icon-2x" }
const _hoisted_14 = {
  class: "nav-item mb-2 d-none",
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  "data-bs-placement": "right",
  "data-bs-dismiss": "click",
  title: "",
  "data-bs-original-title": "Subscription"
}
const _hoisted_15 = {
  class: "nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
  "data-bs-toggle": "tab",
  href: "#kt_aside_nav_tab_subscription"
}
const _hoisted_16 = { class: "svg-icon svg-icon-2x" }
const _hoisted_17 = {
  class: "nav-item mb-2 d-none",
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  "data-bs-placement": "right",
  "data-bs-dismiss": "click",
  title: "",
  "data-bs-original-title": "Tasks"
}
const _hoisted_18 = {
  class: "nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
  "data-bs-toggle": "tab",
  href: "#kt_aside_nav_tab_tasks"
}
const _hoisted_19 = { class: "svg-icon svg-icon-2x" }
const _hoisted_20 = {
  class: "nav-item mb-2 d-none",
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  "data-bs-placement": "right",
  "data-bs-dismiss": "click",
  title: "",
  "data-bs-original-title": "Notifications"
}
const _hoisted_21 = {
  class: "nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
  "data-bs-toggle": "tab",
  href: "#kt_aside_nav_tab_notifications"
}
const _hoisted_22 = { class: "svg-icon svg-icon-2x" }
const _hoisted_23 = {
  class: "nav-item mb-2 d-none",
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  "data-bs-placement": "right",
  "data-bs-dismiss": "click",
  title: "",
  "data-bs-original-title": "Authors"
}
const _hoisted_24 = {
  class: "nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
  "data-bs-toggle": "tab",
  href: "#kt_aside_nav_tab_authors"
}
const _hoisted_25 = { class: "svg-icon svg-icon-2x" }
const _hoisted_26 = {
  class: "aside-footer d-flex flex-column align-items-center flex-column-auto",
  id: "kt_aside_footer"
}
const _hoisted_27 = { class: "d-flex align-items-center mb-2 d-none" }
const _hoisted_28 = {
  class: "btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-overflow": "true",
  "data-kt-menu-placement": "top-start",
  "data-kt-menu-flip": "top-end",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "right",
  "data-bs-dismiss": "click",
  title: "Quick links"
}
const _hoisted_29 = { class: "svg-icon svg-icon-2 svg-icon-lg-1" }
const _hoisted_30 = { class: "d-flex align-items-center mb-2 d-none" }
const _hoisted_31 = {
  class: "btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-overflow": "true",
  "data-kt-menu-placement": "top-start",
  "data-kt-menu-flip": "top-end",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "right",
  "data-bs-dismiss": "click",
  title: "Notifications"
}
const _hoisted_32 = { class: "svg-icon svg-icon-2 svg-icon-lg-1" }
const _hoisted_33 = { class: "d-flex align-items-center mb-3 d-none" }
const _hoisted_34 = {
  class: "btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-overflow": "true",
  "data-kt-menu-placement": "top-start",
  "data-kt-menu-flip": "top-end",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "right",
  "data-bs-dismiss": "click",
  title: "Activity Logs",
  id: "kt_activities_toggle"
}
const _hoisted_35 = { class: "svg-icon svg-icon-2 svg-icon-lg-1" }
const _hoisted_36 = {
  class: "d-flex align-items-center mb-10",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_37 = {
  id: "kt_user_profile",
  class: "cursor-pointer symbol symbol-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-overflow": "true",
  "data-kt-menu-placement": "top-start",
  "data-kt-menu-flip": "top-end",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "right",
  "data-bs-dismiss": "click",
  title: "User profile"
}
const _hoisted_38 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTQuickLinksMenu = _resolveComponent("KTQuickLinksMenu")!
  const _component_KTNotificationsMenu = _resolveComponent("KTNotificationsMenu")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", { href: _ctx.webURL }, [
        _createElementVNode("img", {
          alt: "Logo",
          src: `${_ctx.publicPath}assets/img/logo.jpeg`,
          class: "h-70px"
        }, null, 8, _hoisted_4)
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", _hoisted_7, [
          _createElementVNode("li", _hoisted_8, [
            _createElementVNode("a", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, [
                _createVNode(_component_inline_svg, {
                  src: `${_ctx.publicPath}media/icons/duotune/general/gen025.svg`
                }, null, 8, ["src"])
              ])
            ])
          ]),
          _createElementVNode("li", _hoisted_11, [
            _createElementVNode("a", _hoisted_12, [
              _createElementVNode("span", _hoisted_13, [
                _createVNode(_component_inline_svg, {
                  src: `${_ctx.publicPath}media/icons/duotune/finance/fin006.svg`
                }, null, 8, ["src"])
              ])
            ])
          ]),
          _createElementVNode("li", _hoisted_14, [
            _createElementVNode("a", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen032.svg" })
              ])
            ])
          ]),
          _createElementVNode("li", _hoisted_17, [
            _createElementVNode("a", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen048.svg" })
              ])
            ])
          ]),
          _createElementVNode("li", _hoisted_20, [
            _createElementVNode("a", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs027.svg" })
              ])
            ])
          ]),
          _createElementVNode("li", _hoisted_23, [
            _createElementVNode("a", _hoisted_24, [
              _createElementVNode("span", _hoisted_25, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/files/fil005.svg" })
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("span", _hoisted_29, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen022.svg" })
          ])
        ]),
        _createVNode(_component_KTQuickLinksMenu)
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("span", _hoisted_32, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen025.svg" })
          ])
        ]),
        _createVNode(_component_KTNotificationsMenu)
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("span", _hoisted_35, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen032.svg" })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_36, [
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("img", {
            alt: _ctx.currentUser.name,
            src: _ctx.currentUser.photo
          }, null, 8, _hoisted_38)
        ]),
        _createVNode(_component_KTUserMenu)
      ])
    ])
  ]))
}